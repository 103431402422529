<template>
  <div>
    <div class="main-title">推广员等级设置列表</div>
    <div class="content-list-page page-content">
      <div class="list-head-box">
        <div data-v-69874ad0="" class="list-head">
          <p data-v-69874ad0="" class="list-head-title">温馨提示</p>
          <ul data-v-69874ad0="">
            ********************
          </ul>
        </div>
      </div>

      <div class="padding-t-sm padding-b-sm">
        <span class="fl-l">
          <el-input v-model.trim="name" placeholder="请输入名称"></el-input>
        </span>
        <span class="margin-l-sm fl-l">
          <el-select
            v-model="status"
            clearable
            size="mini"
            placeholder="状态选择"
          >
            <el-option label="禁用" :value="0"></el-option>
            <el-option label="启用" :value="1"></el-option>
          </el-select>
        </span>

        <span class="margin-l-sm fl-l">
          <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="search"
            >搜索</el-button
          >
        </span>

        <span class="fl-r">
          <router-link to="/extension/add" class="router-link">
            <el-button type="primary" size="small">新建等级</el-button>
          </router-link>
        </span>

        <div style="clear: both"></div>
      </div>

      <div class="table-content">
        <el-table
          :header-cell-style="{ padding: 0 }"
          :cell-class-name="rowItem"
          ref="singleTable"
          :data="tableData"
          highlight-current-row
          @current-change="handleCurrentChange"
          v-loading="loading"
          style="width: 100%; background: #fff"
        >
          <el-table-column label="ID" property="id" width="80">
          </el-table-column>
          <el-table-column property="index" label="名称">
            <template slot-scope="scope">
              <span>{{ scope.row.name }}</span>
            </template>
          </el-table-column>
          <el-table-column property="upgrade_start" label="起始金额">
          </el-table-column>
          <el-table-column property="upgrade_end" label="终止金额">
          </el-table-column>
          <el-table-column property="min_withdrawal_amount" label="最低提现金额">
          </el-table-column>
          <el-table-column label="佣金比例">
            <template slot-scope="scope">
              <span>{{ scope.row.commission_rate }}%</span>
            </template>
          </el-table-column>
          <el-table-column label="状态" width="200">
            <template slot-scope="scope">
              <span v-if="scope.row.status == 1" class="state-up"
                ><i></i>启用</span
              >
              <span v-if="scope.row.status == 0" class="state-down"
                ><i></i>禁用</span
              >
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <span>
                <el-link
                  slot="reference"
                  type="primary"
                  @click="editDetail(scope.row)"
                  >编辑</el-link
                > </span
              >-
              <span>
                <el-popconfirm
                  icon="el-icon-info"
                  icon-color="red"
                  title="删除后将不可恢复，确定删除吗？"
                  @confirm="deleted(scope.row)"
                >
                  <el-link slot="reference" type="primary">删除</el-link>
                </el-popconfirm>
              </span>
              -
              <span>
                <el-popconfirm
                  icon="el-icon-info"
                  icon-color="red"
                  title="确定改变状态吗？"
                  @confirm="changeStute(scope.row)"
                >
                  <el-link slot="reference" type="primary">
                    {{ scope.row.status == 0 ? "启用" : "禁用" }}
                  </el-link>
                </el-popconfirm>
              </span>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="table-batch">
        <span class="fl-r">
          <el-pagination
            small
            background
            :current-page="page"
            :page-sizes="[10, 20]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="setPageSize"
            @current-change="setPage"
            :total="total"
          >
          </el-pagination>
        </span>
        <div style="clear: both"></div>
      </div>
    </div>

    <el-dialog
      title="活动分享图"
      :visible.sync="dialogVisible"
      append-to-body
      width="30%"
    >
      <div style="display: flex; justify-content: center">
        <div style="position: relative; width: 270px; height: 380px">
          <img
            class="bg-img"
            :src="imageUrl + sharePic"
            width="270"
            height="380"
          />
          <div class="qrcode">
            <make-share-pic
              :width="120"
              :height="120"
              :url="shareUrl"
            ></make-share-pic>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <div style="display: flex; justify-content: center">
          <el-button @click="dialogVisible = false">关闭</el-button>
        </div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import MakeSharePic from "../../../components/makeSharePic/index";

export default {
  name: "template-list",
  components: { MakeSharePic },
  data() {
    return {
      dialogVisible: false,
      sharePic: "",
      shareUrl: "",
      page: 1,
      pageSize: 10,
      total: 0,
      loading: false,
      imageUrl: config.imageUrl,
      options: [
        {
          value: 1,
          label: "上架",
        },
        {
          value: 0,
          label: "下架",
        },
      ],
      value: "",
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      value2: "",
      tableData: [],
      currentRow: null,
      name: "",
      status: "",
    };
  },
  methods: {
    ...mapActions("extension", ["listLevel", "delLevel", "setLevelStatus"]),
    setCurrent(row) {
      this.$refs.singleTable.setCurrentRow(row);
    },
    handleCurrentChange(val) {
      this.currentRow = val;
    },
    headeCellStyle({ row, rowIndex }) {
      return "{padding:0}";
    },
    rowItem() {
      return "row-item";
    },
    search() {
      this.tableData = [];
      this.page = 1;
      this.getList();
    },
    getList() {
      this.loading = true;
      let data = {
        name: this.name,
        status: this.status,
        page: this.page,
        pageSize: this.pageSize,
      };
      this.listLevel(data).then((res) => {
        this.loading = false;
        this.tableData = res.data.list;
        this.total = res.data.total;
      });
    },

    async deleted(item) {
      const { data, res_info } = await this.delLevel(item.id);
      if (res_info != "ok") return;
      this.$message.success("删除成功！");
      this.getList();
    },
    async changeStute(item) {
      let from = {
        id: item.id,
        status: item.status == 0 ? 1 : 0,
      };
      const { res_info } = await this.setLevelStatus(from);
      if (res_info != "ok") return;
      this.$message.success("修改成功！");
      this.getList();
    },
    setPageSize(pageSize) {
      this.pageSize = pageSize;
      this.getList();
    },
    setPage(page) {
      this.page = page;
      this.getList();
    },
    editDetail(row) {
      this.$router.push("/extension/edit/" + row.id);
    },
    share(row) {
      this.sharePic = row.share_pic;
      this.shareUrl = row.share_url;
      this.dialogVisible = true;
      console.log(row);
    },
    detail(row) {
      this.$router.push("/promotion/detail/" + row.id + "/promotionUsers");
    },
    // 推荐
    toSetRecommend(id) {},
  },
  mounted() {
    this.getList();
  },
};
</script>

<style>
.bg-img {
}

.qrcode {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 5px;
  background: #fff;
  margin: 5px;
}
</style>
